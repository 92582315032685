@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://use.typekit.net/ote0rhg.css');
@import url('https://fonts.googleapis.com/css2?family=Asap:wght@400;500;700&display=swap');

html,
body,
#root {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "HelveticaNeue-Thin";
  src: url(./fonts/HelveticaNeue\ Thin.ttf);
}

@font-face {
  font-family: "HelveticaNeue-Light";
  src: url(./fonts/HelveticaNeue\ Light.ttf);
}

@font-face {
  font-family: "HelveticaNeue-Medium";
  src: url(./fonts/HelveticaNeue\ Medium.ttf);
}

@font-face {
  font-family: "HelveticaNeue-Bold";
  src: url(./fonts/HelveticaNeue\ Bold.ttf);
}




body {
  margin: 0;
  font-family: obvia, sans-serif  !important;
  font-weight: 400 !important;
  font-style: normal !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./assets/background.svg');
}

.background {
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.clickable {
  cursor: pointer;
}

.section-header {
  width: 100%;
  background-color: rgb(233, 236, 239);
  padding: 12px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.buttons, .btn.buttons {
  text-align: right;
  color: white;
  height: auto;
  font-size: 15pt;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  padding-bottom: 10px;
  padding-top: 10px;
}

.inputbox {
  background: transparent;
  color: white;
  border: none;
  border-bottom: 1px solid white;
  font-family: 'HelveticaNeue-Thin';
  font-size: 32px;
}
.inputbox:focus {
  outline: none;
}

.description {
  color: white;
  font-family: 'HelveticaNeue-Thin';
  font-size: 16px;
}

.input-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: white;
}
.input-container > .icon {
  width: 40px;
  margin-bottom: 48px;
}
.input-container > .input-tag {
  width: 100%;
}
.input-container > * {
  margin-right: 8px;
}
.input-container > *:last-child {
  margin-right: 0;
}
.input-tag > .inputbox {
  width: 100%;
}
.input-tag > .tag {
  color: white;
  font-family: 'HelveticaNeue-Thin';
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 10px;
  text-align: left;
}

.stat-container {
  background-color: #121212;
  color: white;
  width: 100%;
  padding-top: 40px;
  padding-left: 48px;
  padding-right: 48px;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.stat-header {
  font-family: 'HelveticaNeue-Thin';
  font-size: 32px;
  text-align: center;
}

.collapse-nav {
  cursor: pointer;
}

.content-container {
  width: 100%;
  padding: 24px;
  color: white;
}

.label > i{
font-size: 2.5rem;
}

.label > span {
  color: white;
  font-family: 'HelveticaNeue-Light';
  font-size: 24px;
  margin-left: 1rem;
}

.buton {
  background-color: transparent;
  color: white;
  font-family: 'HelveticaNeue-Medium';
  font-size: 12px;
  border: 1px solid white;
  text-transform: uppercase;
  height: 36px;
  width: 100%;
}

.buton:hover {
  background-color: white;
  color: black;
}

input[type=date]::-webkit-calendar-picker-indicator { 
  filter: invert(1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.overlay-spinner-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  border-radius: 8px;
  border: 1px solid black;
  background-color: darkslategray;
  color: white;
  width: calc(100% - 32px);
  max-width: 350px;
}


input.form-control, textarea.form-control, .form-control:disabled, .form-control[readonly] {
  border-radius:  0;
  background-color: transparent !important;
  border-color: grey;
  padding: 14px;
  color:#FFF;
}
input.form-control:focus, textarea.form-control:focus  {
  color:#FFF;
  border-radius:  0;
  background-color: transparent;
  border-color: grey;
  padding: 14px;
}


.verySmall{
  font-size: .55rem !important;
  position: relative;
  top: -1px;
  display: block;
}
.mystyle{
  color: "white";
  background-color: "DodgerBlue";
  padding: "10px";
  display: flex;
  

}
.pagination-container {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  background-color:inherit;

}
.pagination-containerleft{
  display: flex;
  list-style: none;
  /* align-items: center; */
  /* justify-content: center; */
  background-color:inherit;

  
}


.pagination-item {
  margin: 0 5px;


 height:25px;
 width: 25px;
 text-align: center;
 margin-left: 5px;
}

.pagination-item-active {
  font-weight: bold;
  height: 26px;
  width: 26px;
  border: 1px solid #fff;
}

.pagination-previous,
.pagination-next {
  margin: 0 25px;
}

.pagination-link {
  text-decoration: none;
  color: #fff;
}



.hidden {
  /* display: none; */
 color: aqua;
  
}

.pagination-link[disabled]:hover {
  text-decoration: none !important;
}