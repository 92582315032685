button.dropdown-custom-btn {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  color: #000E1B;
  align-items: center;
  border: none;
}

button.dropdown-custom-btn:active {
  width: 100%;
  background-color: white !important;
  display: flex;
  justify-content: space-between;
  color: #000E1B !important;
  align-items: center;
  border: none !important;
}

button.dropdown-custom-btn:focus {
  width: 100%;
  background-color: white !important;
  display: flex;
  justify-content: space-between;
  color: #000E1B !important;
  align-items: center;
  border: none !important;
}

a.dropdown-custom-item {
  width: 100%;
  color: #000E1B;
  background-color: transparent;
}

a.dropdown-custom-item.active, 
.dropdown-custom-item:active {
  width: 100%;
  background-color: #000E1B;
  color: white;
}

div.dropdown-custom-menu {
  width: 100%;
  color: #000E1B;
  background-color: white;
}

input.dropdown-custom-filter-input {
  width: calc(100% - 2rem) !important;
}

input.dropdown-custom-filter-input-color:focus {
  color: #000E1B;
}

a.dropdown-custom-toggle-btn {
  color: #000E1B;
}

a.dropdown-custom-toggle-btn:hover {
  color: #000E1B;
}
