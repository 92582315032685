/* Header style */

.header-container {
  width: 100%;
  top: 0px;
  height: 200px;
}

.header-container.inner {
  background-color: #FFF;
  height: 50px;
}
/* Icon style */

.backicon-container {
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  cursor: pointer;
  position: absolute;
  z-index: 10;
}

.menuicon-container {
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  cursor: pointer;
  position: absolute;
  right: 0;
  z-index: 10;
}

.logo-container {
  z-index: 9;
  width: 100%;
  text-align: center;
}

.logo {
  cursor: pointer;
}

.nav-menu {
  background-color: #0c0c0d;
  color: #d8d8d8;
  position: absolute;
  z-index: 8;
  top: 50px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
}

.nav-menu-enter {
  height: 0;
}
.nav-menu-enter-active {
  height: 80vh;
  transition: height 200ms;
}
.nav-menu-exit {
  height: 80vh;
}
.nav-menu-exit-active {
  height: 0;
  transition: height 200ms;
}

.menu-item {
  background-color: transparent;
  color: #d8d8d8;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  text-transform: uppercase;
  height: 36px;
  margin-left: 71px;
  margin-right: 71px;
}

.menu-item:hover {
  background-color: #d8d8d8;
  color: black;
}

.menu-logo {
  margin-top: auto;
}

.menu-logo-icon {
  border-radius: 20%;
}

.copyright {
  margin-top: 40px;
  margin-left: 60px;
  user-select: none;
}


@media only screen and (max-height: 780px) {
  .header-container {
    height: 150px;
  } 
}
