.invite-user-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 85%;
}

input[type=checkbox] {
  height: 1.5rem;
  width: 1.5rem;
}

.form-check-label {
  margin-left: 1rem;
  font-size: 1.5rem;
  color: #fff;
}