.DashBoard {
  height: 100%; 
  overflow:auto;
}


.active {
  background-color: #64C579;
}
.new_post_mortem_btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}


@media only screen and (max-width: 375px) {
  .dashboardBtn {
    font-size: 14px;
  }
}
