select.submit-scheduled-text,
.submit-scheduled-text {
  font-size: 2.25rem;
}
.login-toast-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  width: fit-content;
  max-width: 100%;
}

.toast-header-success {
  color: #ffffff;
  background-color: #218838;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-clip: padding-box;
  /* border-bottom: 1px solid #f5c6cb; */
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  opacity:0.5;
}

@media only screen and (max-width: 525px) {
  select.submit-scheduled-text,
  .submit-scheduled-text {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 425px) {
  select.submit-scheduled-text,
  .submit-scheduled-text {
    font-size: 1.8rem;
  }
}


@media only screen and (max-width: 375px) {
  select.submit-scheduled-text,
  .submit-scheduled-text {
    font-size: 1.2rem;
  }
}
