.login-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60%;
}

.login-button {
  margin-top: 50px;
  border: 2px solid #4dbd74;
  background-color: transparent;
  padding: 12px 34px;
  margin: 3px;
  color: white;
  text-align: center;
  width: 170px;
  display: inline;
  min-width: 0;
}

.login-button:hover {
  font-style: italic;
  text-decoration: underline;
}

.recover-container {
  cursor: pointer;
  margin-bottom: 50px;
}

.recover-password-text {
  font-size: 16px;
  color: #5a5a5a;
  font-weight: 400;
  border-bottom: 2px solid #5a5a5a;
}

.recover-password-text:hover {
  text-decoration: none;
}

.error-container {
  padding: 5px 13px 5px 0;
  height: 38px;
  font-size: 1.2rem;
}

.login-toast-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  width: fit-content;
  max-width: 100%;
}

.toast-header-error {
  color: #721c24;
  background-color: #f8d7da;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-clip: padding-box;
  border-bottom: 1px solid #f5c6cb;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header-success {
  color: #ffffff;
  background-color: #218838;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-clip: padding-box;
  border-bottom: 1px solid #f5c6cb;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.img-eye {
  right: 10px;
  top: 12px;
  position: absolute;
}

@media only screen and (max-height: 780px) {
  .error-container {
    padding: 5px 13px 5px 0;
    height: 33px;
    font-size: 1rem;
  }
}
