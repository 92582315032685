.myIcon {
    border: 5px red solid !important;
    transform: scale(0.55);
}
.center {
    position:absolute;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.circle {
    font-size: 32px;    
    display: block;
    color:#005D31;
 
}