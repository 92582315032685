.DashBoard {
  height: 100%;
  overflow: auto;

}


.DashBoard i {}

.new_post_mortem_btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}

.active {
  background-color: #64C579;
}

@media only screen and (max-width: 375px) {
  .dashboardBtn {
    font-size: 14px;
  }
}
