.card-container {
  border: 1px solid #dee2e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 400px;
}

.card-container-modal {
  border: 1px solid #dee2e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
}

.remove-photo-btn {
  text-decoration: underline;
  align-self: flex-start;
  padding: 10px 4px;
}

.photos-container {
  display: flex;
  padding: 0 24px 0 24px;
  flex-wrap: wrap;
  justify-content: center;
}

.photos-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.photos-loading-color {
  color: #000e1c;
}

.photos-container-modal {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.no-photo-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-btn {
  height: 50px;
  max-width: 750px;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  border: 1px solid white;
}

.upload-btn-container {
  display: flex;
  width: 100%;
  padding: 24px 24px 0 24px;
  justify-content: center;
}

.upload-photo-check-btn {
  position: fixed;
  bottom: 93px;
  left: 50%;
  transform: translateX(-50%);
}

.upload-photo-text-pin {
  font-size: 3rem;
}

.upload-photo-text-rfid {
  font-size: 2.25rem;
}

.card-photo {
  width: 384px;
  height: 100%;
  object-fit: cover;
}

.card-photo-modal {
  width: 154px;
  height: 100%;
  object-fit: cover;
}

.show-photo-container {
  background-color: rgba(32, 32, 32, 0.849);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  position: fixed;
  top: 0px;
  right: 0;
  z-index: 10000;
}

.show-photo-close-btn {
  position: fixed;
  top: 60px;
  right: 20px;
  font-size: 3rem;
}

.card-photo-container {
  width: 384px;
  height: 100%;
}

.card-photo-container-modal {
  width: 154px;
  height: 100%;
}

@media only screen and (max-width: 1024px) {
  .card-container-modal {
    width: 245px;
    height: 245px;
  }

  .card-photo-modal {
    width: 229px;
    height: 100%;
  }

  .card-photo-container-modal {
    width: 229px;
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .card-container-modal {
    width: 210px;
    height: 210px;
  }

  .card-photo-modal {
    width: 194px;
    height: 100%;
  }

  .card-photo-container-modal {
    width: 194px;
    height: 100%;
  }
}

@media only screen and (max-width: 588px) {
  .upload-photo-text-pin {
    font-size: 2.2rem;
  }

  .upload-photo-text-rfid {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 425px) {
  .card-photo-container {
    width: 334px;
    height: 100%;
  }

  .card-photo {
    width: 334px;
    height: 100%;
  }

  .card-container {
    width: 350px;
    height: 350px;
  }
}
@media only screen and (max-width: 414px) {
  .card-container-modal {
    width: 173px;
    height: 173px;
  }

  .card-photo-modal {
    width: 157px;
    height: 100%;
  }

  .card-photo-container-modal {
    width: 157px;
    height: 100%;
  }
}

@media only screen and (max-width: 375px) {
  .card-photo-container {
    width: 284px;
    height: 100%;
  }

  .card-photo {
    width: 284px;
    height: 100%;
  }

  .card-container {
    width: 300px;
    height: 300px;
  }

  .card-container-modal {
    width: 300px;
    height: 300px;
  }

  .card-photo-modal {
    width: 284px;
    height: 100%;
  }

  .card-photo-container-modal {
    width: 284px;
    height: 100%;
  }
}

@media only screen and (max-width: 325px) {
  .card-photo-container {
    width: 252px;
    height: 100%;
  }

  .card-photo {
    width: 252px;
    height: 100%;
  }

  .card-container {
    width: 270px;
    height: 270px;
  }

  .card-container-modal {
    width: 270px;
    height: 270px;
  }

  .card-photo-modal {
    width: 254px;
    height: 100%;
  }

  .card-photo-container-modal {
    width: 254px;
    height: 100%;
  }
}

.modal-photo-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.modal-photo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
}

.modal-photo-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 90%;
  margin: auto;
}

.modal-photo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  max-width: 50%;
  max-height: 80%;
  margin: 10px 0;
  overflow: hidden;
}

.modal-photo {
  width: 100%;
  height: 100%;
  cursor: grab;
  user-select: none;
}

.modal-photo-controls {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.modal-photo-zoom-in,
.modal-photo-zoom-out,
.modal-photo-reset,
.modal-photo-close {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 3;
}

.modal-photo-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
}

.modal-photo-prev,
.modal-photo-next {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 10px;
  cursor: pointer;
  margin:10;
  z-index: 3;
}

@media (max-width: 768px) {
  .modal-photo-content {
    flex-direction: column;
    max-width: 95%;
    max-height: 95%;
  }

  .modal-photo-wrapper {
    max-height: 60%;
  }

  .modal-photo-controls {
    flex-wrap: wrap;
    justify-content: center;
  }

  .modal-photo-nav {
    flex-wrap: wrap;
    justify-content: center;
  }
  .modal-photo-wrapper {
    max-width: 100%;
  }
}
