
.grid-select-container {
	display: flex;
	color: white;
}
.grid-select-container .icon {
	width: 40px;
	margin-right: 8px;
}

.grid-select {
	width: 100%;
	border: 1px solid white;
	border-collapse: collapse;
	text-align: center;
	font-family: 'HelveticaNeue-Medium';
	user-select: none;
}
.grid-select td {
	width: 33.33%;
	height: 36px;
	border: 1px solid white;
	border-collapse: collapse;
	font-size: 12px;
	text-transform: uppercase;
	cursor: pointer;
}
.grid-select td:hover {
	background-color: white;
	color: black;
}
.grid-select td.selected {
	background-color: #0068BE;
	color: white;
}

.tbl-tag {
	font-family: 'HelveticaNeue-Thin';
	font-size: 12px;
	text-transform: uppercase;
	margin-top: 10px;
	text-align: left;
}
