
.filters-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.filter {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 12px;
}

.filter-icon {
  font-size: 24px;
  margin-top: 12px;
}

.filter-border {
  background-color: white;
  margin-top: 12px;
  margin-bottom: 0;
}

.filter-text {
  font-size: 12px;
  font-family: 'HelveticaNeue-Thin';
}
