/* Header style */

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 10;
}

i {
font-size: 1.5rem;;
}

.scan-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  cursor: pointer;
  padding: 6px;
}

.scan-container * {
  margin: 6px;
}

.footer-btns-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 0;
  
}

.footer-btn {
  justify-content: center;
  height: 60px;
  width: 100%;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.footer-btn.active i {
  position: relative;
}

.footer-btn.active i::after {
  content: '';
  position: absolute;
  border: 2px solid white;
  border-radius: 50%;
  bottom: -35%;
  left: 50%;
  transform: translate(-50%, 0);
}
