.edit-profile-btn {
  margin-top: 50px;
  border: 2px solid #4dbd74;
  background-color: transparent;
  padding: 12px 34px;
  margin: 3px;
  color: white;
  text-align: center;
  width: 205px;
  display: inline;
  min-width: 0;
}

.edit-container {
  height: 100%;
  max-height: 386px;
}
