.login-container {
  min-height: 368px;
}

.login-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60%;
}

.reset-button {
  margin-top: 50px;
  border: 2px solid #4dbd74;
  background-color: transparent;
  padding: 12px 34px;
  margin: 3px;
  color: white;
  text-align: center;
  width: 158px;
  display: inline;
  min-width: 0;
}

.reset-button:hover {
  font-style: italic;
  text-decoration: underline;
}

.recover-container {
  cursor: pointer;
}

.recover-container-text {
  color: #6C757D;
  margin-bottom: 42px;
}

.recover-password-text {
  font-size: 16px;
  color: #5a5a5a;
  font-weight: 400;
  border-bottom: 2px solid #5a5a5a;
}

.recover-password-text:hover {
  text-decoration: none;
}

.error-container {
  min-height: 1.2em;
}
