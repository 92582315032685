div.dropdown-menu {
  color: #fff;
  border-color: grey;
  background-color: #000d22;
}

a.dropdown-item {
  color: #fff;
}

.dropdown-item-list {
  height: auto;
  max-height: 190px;
  overflow-y: auto;
}

a.dropdown-item.active,
a.dropdown-item:active {
  background-color: #fff;
  border-color: grey;
  color: #000d22;
}

a.dropdown-toggle-btn,
div.dropdown-toggle-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 6px 14px;
  white-space: normal;
}

.dropdown-toggle-btn:hover {
  text-decoration: none;
}

span.dropdown-toggle-btn-text {
  white-space: nowrap;
  overflow-x: auto;
  text-overflow: ellipsis;
}

div.dropdown {
  border: 1px solid #ced4da;
  border-radius: 0;
  background-color: transparent !important;
  border-color: grey;
  color: #fff;
}

div.dropdown:focus {
  color: #fff;
  border-radius: 0;
  background-color: transparent;
  border-color: grey;
  padding: 24px;
}

button.dropdown-btn-light{
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  color: #000E1B;
  align-items: center;
  border: none;
}

button.dropdown-btn-light:active {
  width: 100%;
  background-color: white !important;
  display: flex;
  justify-content: space-between;
  color: #000E1B !important;
  align-items: center;
  border: none !important;
}

button.dropdown-btn-light:focus {
  width: 100%;
  background-color: white !important;
  display: flex;
  justify-content: space-between;
  color: #000E1B !important;
  align-items: center;
  border: none !important;
}

.customloader{
  width: 100%;
  height: 80vh;
  background-color:'red';
display: flex;
justify-content:center;
align-items:center;
position: absolute;
top: 100px ;
}
a.dropdown-item-light {
  width: 100%;
  color: #000E1B;
  background-color: transparent;
}

a.dropdown-item-light.active, 
.dropdown-item-light:active {
  width: 100%;
  background-color: #000E1B;
  color: white;
}

div.dropdown-menu-light {
  width: 100%;
  color: #000E1B;
  background-color: white;
}

input.dropdown-filter-input-dark,
input.dropdown-filter-input-light {
  width: calc(100% - 2rem);
}

input.dropdown-filter-input-light:focus {
  color: #000E1B;
}

a.dropdown-toggle-btn-light {
  color: #000E1B;

}

a.dropdown-toggle-btn-light:hover {
  color: #000E1B;
}
