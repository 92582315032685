.PageWrapper {
  font-size: 1.5rem;
  background-color: #FFF;
}

.PageWrapper h1 {
  font-size: 1.45rem;
}

.title {
  background-color: #FFF;
  padding: 10px 24px;
  margin-bottom: 0;
  max-width: calc(100vw - 130px);
}

@media only screen and (max-width: 600px) {
  .title {
    max-width: calc(100vw - 90px);
  }
}

@media only screen and (max-width: 400px) {
  .title {
    max-width: calc(100vw - 70px);
  }
}

.curve-bg {
  background-color: #FFF;
}

.curve-base {
  height: 30px;
  background-image: url("../../../assets/background.svg");
}
.curve-left {
  border-top-left-radius: 300px;
}
.curve-right {
  border-top-right-radius: 300px;
}

.icon {
  right: 70px;
  top: -40px;
}

@media only screen and (max-width: 600px) {
  .icon {
    right: 40px;
  }
}

@media only screen and (max-width: 400px) {
  .icon {
    right: 20px;
  }
}
